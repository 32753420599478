import React, { Component } from 'react';
import { io } from 'socket.io-client';
import { LIVEMODES } from './../../enums/';
import logo from './../../assets/images/logo.svg';
import classNames from 'classnames';
import Track from './../track/Track';
import EventType from './../eventType/EventType';
import EventCard from './../eventCard/EventCard';

import './style.scss';
import { ReactComponent as Livetiming } from './../../assets/livemodes/Livetiming.svg';
import { ReactComponent as Startgrid } from './../../assets/livemodes/Startgrid.svg';
import { ReactComponent as Timetable } from './../../assets/livemodes/Timetable.svg';
import { ReactComponent as LivetimingCondensed } from './../../assets/livemodes/LivetimingCondensed.svg';
import { ReactComponent as DriverStrip } from './../../assets/livemodes/Driverstrip.svg';
import { ReactComponent as DriverList } from './../../assets/livemodes/DriverList.svg';
import { ReactComponent as DriverListStart } from './../../assets/livemodes/DriverList-Start.svg';
import { ReactComponent as DriverListFinish } from './../../assets/livemodes/DriverList-Finish.svg';
import { ReactComponent as Blank } from './../../assets/livemodes/Blank.svg';

import {
  Row,
  Jumbotron,
  Col,
  Input,
  TabContent,
  TabPane,
  Button,
  Nav,
  NavItem,
  NavLink,
  Container,
} from 'reactstrap';

class Event extends Component {
  constructor(props) {
    super(props);
    this._timer = null;
    const socketUrl = `${process.env.REACT_APP_BASE_API_URL}/admin`;
    const API_KEY =
      '8J#dSKejg^5SO4#dasd@jginp7X^dasd&dsa@jKjbv4Bi6z#FE&TM&cms^mS@z!4*75das3212€%€**^dds34331B&dasdadsadas';
    const eventId = this.props.eventId;

    this._socket = io(socketUrl, {
      path: '/socket.io/',
      transports: ['websocket'],
      query: `key=${encodeURIComponent(API_KEY)}&eventId=${eventId}`,
      'connect timeout': 5000,
    });

    this._socket.on('client-race-data', (heat) => {
      this.props.updateLiveHeatData(heat);
    });
    this._socket.on('admin-live-mode', (mode) => {
      this.props.updateLiveMode(mode);
    });
    this.state = {
      timeTable: '',
      activeTab: '1',
    };
    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    await this.setState({
      [name]: value,
    });
  };

  componentDidMount() {
    const eventId = this.props.eventId;
    this.props.fetchLiveHeatToEvent(eventId);
  }

  componentWillReceiveProps(nextProps) {
    // This will erase any local state updates!
    // Do not do this.
    this.setState({ timeTable: nextProps.event.timeTable });
  }

  componentWillUnmount() {
    this._socket.disconnect();
  }

  setLiveMode(livemode) {
    this._socket.emit('admin-live-mode', livemode);
  }

  handleSave() {
    this.props.saveTimeTable(this.state.timeTable, this.props.eventId);
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    const { event, liveHeat, mode } = this.props;
    const pulseClass = classNames('pulse', event.live ? 'active' : '');
    const liveStatus = event.live ? 'Live' : 'Offline';

    const tvUrl = `https://www.scorit.live/tv/${event.externalId}/1920/1080`;
    const baseModeUrl = `${tvUrl}?mode=`;
    const liveUrl = `https://www.scorit.live/live/${event.externalId}`;
    const eventUrl = `https://www.scorit.live/events/${event.externalId}`;
    const refreshUrl = `https://api.scorit.live/v1/events/${this.props.eventId}/tv/refresh?screen=`;
    const pushModeUrl = `https://api.scorit.live/v1/events/${this.props.eventId}/tv/pushlivemode?screen=`;
    const { timeTable } = this.state;
    if (Object.keys(event).length === 0) return null;
    return (
      <div className="event">
        <Jumbotron>
          <EventType eventType={event.eventType} />
          <div className="live-status">
            <span>{liveStatus} </span>
            <span className={pulseClass}></span>
          </div>
          <Row>
            <Col sm="3">
              <Track fromCms track={event.track} />
            </Col>
            <Col>
              <h1>{event.eventName}</h1>
              <h3>{event.track?.trackName}</h3>

              <div>
                <a href={eventUrl} target="blank">
                  {eventUrl}
                </a>
              </div>
              <div>
                <a href={liveUrl} target="blank">
                  {liveUrl}
                </a>
              </div>
            </Col>
          </Row>
        </Jumbotron>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={`${this.state.activeTab === '1' ? 'active' : ''}`}
              onClick={() => {
                this.toggle('1');
              }}
            >
              Stream deck
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={`${this.state.activeTab === '2' ? 'active' : ''}`}
              onClick={() => {
                this.toggle('2');
              }}
            >
              Links
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={`${this.state.activeTab === '3' ? 'active' : ''}`}
              onClick={() => {
                this.toggle('3');
              }}
            >
              Settings
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <Container>
              <Row>
                <Col>
                  <Row>
                    <Col>
                      <h4>Live timing</h4>
                    </Col>
                  </Row>
                  <Row>
                    <EventCard
                      title="Livetiming"
                      active={mode === LIVEMODES.TIMING}
                      ImageComponent={Livetiming}
                      size="3"
                      onClick={() => this.setLiveMode(LIVEMODES.TIMING)}
                    />
                    <EventCard
                      title="Livetiming Condensed"
                      active={mode === LIVEMODES.TIMINGCONDENSED}
                      ImageComponent={LivetimingCondensed}
                      size="3"
                      onClick={() => this.setLiveMode(LIVEMODES.TIMINGCONDENSED)}
                    />
                    <EventCard
                      title="Drivers strip"
                      active={mode === LIVEMODES.DRIVERSTRIP}
                      ImageComponent={DriverStrip}
                      size="3"
                      onClick={() => this.setLiveMode(LIVEMODES.DRIVERSTRIP)}
                    />
                    <EventCard
                      title="Blank screen"
                      active={mode === LIVEMODES.BLANK}
                      ImageComponent={Blank}
                      size="3"
                      onClick={() => this.setLiveMode(LIVEMODES.BLANK)}
                    />
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Row>
                    <Col>
                      <h4>Driver tables</h4>
                    </Col>
                  </Row>
                  <Row>
                    <EventCard
                      title="Startgrid"
                      active={mode === LIVEMODES.STARTGRID}
                      ImageComponent={Startgrid}
                      size="3"
                      onClick={() => this.setLiveMode(LIVEMODES.STARTGRID)}
                    />
                    <EventCard
                      title="Startgrid left"
                      subtitle="Pole left"
                      active={mode === LIVEMODES.STARTGRID_LEFT}
                      ImageComponent={Startgrid}
                      size="3"
                      onClick={() => this.setLiveMode(LIVEMODES.STARTGRID_LEFT)}
                    />
                    <EventCard
                      title="Startgrid right"
                      subtitle="Pole right"
                      active={mode === LIVEMODES.STARTGRID_RIGHT}
                      ImageComponent={Startgrid}
                      size="3"
                      onClick={() => this.setLiveMode(LIVEMODES.STARTGRID_RIGHT)}
                    />
                    <EventCard
                      title="Startgrid table"
                      subtitle="Current drivers start position"
                      active={mode === LIVEMODES.STARTGRIDTABLE}
                      ImageComponent={DriverListStart}
                      size="3"
                      onClick={() => this.setLiveMode(LIVEMODES.STARTGRIDTABLE)}
                    />
                    <EventCard
                      title="Drivers table"
                      subtitle="Current drivers"
                      active={mode === LIVEMODES.DRIVERTABLE}
                      ImageComponent={DriverList}
                      size="3"
                      onClick={() => this.setLiveMode(LIVEMODES.DRIVERTABLE)}
                    />
                    <EventCard
                      title="Result table"
                      subtitle="Last run with finish flag"
                      active={mode === LIVEMODES.RESULTTABLE}
                      ImageComponent={DriverListFinish}
                      size="3"
                      onClick={() => this.setLiveMode(LIVEMODES.RESULTTABLE)}
                    />
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Row>
                    <Col>
                      <h4>Live timing - Endurance</h4>
                    </Col>
                  </Row>
                  <Row>
                    <EventCard
                      title="Livetiming"
                      active={mode === LIVEMODES.TIMINGENDURANCE}
                      ImageComponent={Livetiming}
                      size="3"
                      onClick={() => this.setLiveMode(LIVEMODES.TIMINGENDURANCE)}
                    />
                    <EventCard
                      title="Livetiming Condensed"
                      active={mode === LIVEMODES.TIMINGCONDENSEDENDURANCE}
                      ImageComponent={LivetimingCondensed}
                      size="3"
                      onClick={() => this.setLiveMode(LIVEMODES.TIMINGCONDENSEDENDURANCE)}
                    />
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Row>
                    <Col>
                      <h4>Information</h4>
                    </Col>
                  </Row>
                  <Row>
                    <EventCard
                      title="Timetable"
                      active={mode === LIVEMODES.TIMETABLE}
                      ImageComponent={Timetable}
                      size="3"
                      onClick={() => this.setLiveMode(LIVEMODES.TIMETABLE)}
                    />
                  </Row>
                </Col>
              </Row>
            </Container>
          </TabPane>
          <TabPane tabId="2">
            <Container>
              <Row>
                <Col sm="12">
                  <h4>Default link</h4>
                  <div>
                    <a href={`${tvUrl}`} target="blank">{`${tvUrl}`}</a>
                  </div>
                  <h4>Links to fixed screens</h4>
                  <div>
                    <a
                      href={`${baseModeUrl}${LIVEMODES.BLANK}`}
                      target="blank"
                    >{`${baseModeUrl}${LIVEMODES.BLANK}`}</a>
                  </div>
                  <div>
                    <a
                      href={`${baseModeUrl}${LIVEMODES.TIMING}`}
                      target="blank"
                    >{`${baseModeUrl}${LIVEMODES.TIMING}`}</a>
                  </div>
                  <div>
                    <a
                      href={`${baseModeUrl}${LIVEMODES.TIMINGCONDENSED}`}
                      target="blank"
                    >{`${baseModeUrl}${LIVEMODES.TIMINGCONDENSED}`}</a>
                  </div>
                  <div>
                    <a
                      href={`${baseModeUrl}${LIVEMODES.TIMINGCONDENSEDENDURANCE}`}
                      target="blank"
                    >{`${baseModeUrl}${LIVEMODES.TIMINGCONDENSEDENDURANCE}`}</a>
                  </div>
                  <div>
                    <a
                      href={`${baseModeUrl}${LIVEMODES.TIMINGENDURANCE}`}
                      target="blank"
                    >{`${baseModeUrl}${LIVEMODES.TIMINGENDURANCE}`}</a>
                  </div>
                  <div>
                    <a
                      href={`${baseModeUrl}${LIVEMODES.STARTGRID}`}
                      target="blank"
                    >{`${baseModeUrl}${LIVEMODES.STARTGRID}`}</a>
                  </div>
                  <div>
                    <a
                      href={`${baseModeUrl}${LIVEMODES.STARTGRID_LEFT}`}
                      target="blank"
                    >{`${baseModeUrl}${LIVEMODES.STARTGRID_LEFT}`}</a>
                  </div>
                  <div>
                    <a
                      href={`${baseModeUrl}${LIVEMODES.STARTGRID_RIGHT}`}
                      target="blank"
                    >{`${baseModeUrl}${LIVEMODES.STARTGRID_RIGHT}`}</a>
                  </div>
                  <div>
                    <a
                      href={`${baseModeUrl}${LIVEMODES.STARTGRIDTABLE}`}
                      target="blank"
                    >{`${baseModeUrl}${LIVEMODES.STARTGRIDTABLE}`}</a>
                  </div>
                  <div>
                    <a
                      href={`${baseModeUrl}${LIVEMODES.DRIVERTABLE}`}
                      target="blank"
                    >{`${baseModeUrl}${LIVEMODES.DRIVERTABLE}`}</a>
                  </div>
                  <div>
                    <a
                      href={`${baseModeUrl}${LIVEMODES.DRIVERTABLEENDURANCE}`}
                      target="blank"
                    >{`${baseModeUrl}${LIVEMODES.DRIVERTABLEENDURANCE}`}</a>
                  </div>
                  <div>
                    <a
                      href={`${baseModeUrl}${LIVEMODES.RESULTTABLE}`}
                      target="blank"
                    >{`${baseModeUrl}${LIVEMODES.RESULTTABLE}`}</a>
                  </div>
                  <div>
                    <a
                      href={`${baseModeUrl}${LIVEMODES.TIMETABLE}`}
                      target="blank"
                    >{`${baseModeUrl}${LIVEMODES.TIMETABLE}`}</a>
                  </div>

                  <h4>Links to screen push live mode</h4>
                  <div>
                    <a
                      href={`${pushModeUrl}${LIVEMODES.BLANK}`}
                      target="blank"
                    >{`${pushModeUrl}${LIVEMODES.BLANK}`}</a>
                  </div>
                  <div>
                    <a
                      href={`${pushModeUrl}${LIVEMODES.TIMING}`}
                      target="blank"
                    >{`${pushModeUrl}${LIVEMODES.TIMING}`}</a>
                  </div>
                  <div>
                    <a
                      href={`${pushModeUrl}${LIVEMODES.TIMINGCONDENSED}`}
                      target="blank"
                    >{`${pushModeUrl}${LIVEMODES.TIMINGCONDENSED}`}</a>
                  </div>
                  <div>
                    <a
                      href={`${pushModeUrl}${LIVEMODES.TIMINGENDURANCE}`}
                      target="blank"
                    >{`${pushModeUrl}${LIVEMODES.TIMINGENDURANCE}`}</a>
                  </div>
                  <div>
                    <a
                      href={`${pushModeUrl}${LIVEMODES.TIMINGCONDENSEDENDURANCE}`}
                      target="blank"
                    >{`${pushModeUrl}${LIVEMODES.TIMINGCONDENSEDENDURANCE}`}</a>
                  </div>
                  <div>
                    <a
                      href={`${pushModeUrl}${LIVEMODES.STARTGRID}`}
                      target="blank"
                    >{`${pushModeUrl}${LIVEMODES.STARTGRID}`}</a>
                  </div>
                  <div>
                    <a
                      href={`${pushModeUrl}${LIVEMODES.STARTGRID_LEFT}`}
                      target="blank"
                    >{`${pushModeUrl}${LIVEMODES.STARTGRID_LEFT}`}</a>
                  </div>
                  <div>
                    <a
                      href={`${pushModeUrl}${LIVEMODES.STARTGRID_RIGHT}`}
                      target="blank"
                    >{`${pushModeUrl}${LIVEMODES.STARTGRID_RIGHT}`}</a>
                  </div>
                  <div>
                    <a
                      href={`${pushModeUrl}${LIVEMODES.STARTGRIDTABLE}`}
                      target="blank"
                    >{`${pushModeUrl}${LIVEMODES.STARTGRIDTABLE}`}</a>
                  </div>
                  <div>
                    <a
                      href={`${pushModeUrl}${LIVEMODES.DRIVERTABLE}`}
                      target="blank"
                    >{`${pushModeUrl}${LIVEMODES.DRIVERTABLE}`}</a>
                  </div>
                  <div>
                    <a
                      href={`${pushModeUrl}${LIVEMODES.DRIVERTABLEENDURANCE}`}
                      target="blank"
                    >{`${pushModeUrl}${LIVEMODES.DRIVERTABLEENDURANCE}`}</a>
                  </div>
                  <div>
                    <a
                      href={`${pushModeUrl}${LIVEMODES.RESULTTABLE}`}
                      target="blank"
                    >{`${pushModeUrl}${LIVEMODES.RESULTTABLE}`}</a>
                  </div>
                  <div>
                    <a
                      href={`${pushModeUrl}${LIVEMODES.TIMETABLE}`}
                      target="blank"
                    >{`${pushModeUrl}${LIVEMODES.TIMETABLE}`}</a>
                  </div>

                  <h4>Links to screen refresh</h4>

                  <div>
                    <a
                      href={`${refreshUrl}${LIVEMODES.STARTGRID}`}
                      target="blank"
                    >{`${refreshUrl}${LIVEMODES.STARTGRID}`}</a>
                  </div>
                  <div>
                    <a
                      href={`${refreshUrl}${LIVEMODES.STARTGRIDTABLE}`}
                      target="blank"
                    >{`${refreshUrl}${LIVEMODES.STARTGRIDTABLE}`}</a>
                  </div>
                  <div>
                    <a
                      href={`${refreshUrl}${LIVEMODES.DRIVERTABLE}`}
                      target="blank"
                    >{`${refreshUrl}${LIVEMODES.DRIVERTABLE}`}</a>
                  </div>
                  <div>
                    <a
                      href={`${refreshUrl}${LIVEMODES.DRIVERTABLEENDURANCE}`}
                      target="blank"
                    >{`${refreshUrl}${LIVEMODES.DRIVERTABLEENDURANCE}`}</a>
                  </div>
                  <div>
                    <a
                      href={`${refreshUrl}${LIVEMODES.RESULTTABLE}`}
                      target="blank"
                    >{`${refreshUrl}${LIVEMODES.RESULTTABLE}`}</a>
                  </div>
                </Col>
              </Row>
            </Container>
          </TabPane>
          <TabPane tabId="3">
            <Container>
              <Row>
                <Col>
                  <Row>
                    <Col xs="12">
                      <Row>
                        <Col xs="12">
                          <h3>Time Table</h3>
                        </Col>
                        <Col xs="12">
                          <h6>Copy to excel and modify</h6>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12">
                          <Button
                            onClick={() => {
                              this.handleSave();
                            }}
                          >
                            Save
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12">
                          <Input
                            name="timeTable"
                            id="timeTable"
                            value={timeTable}
                            type="textarea"
                            rows="10"
                            onChange={(e) => {
                              this.handleChange(e);
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </TabPane>
        </TabContent>

        <Row>
          <Col xs="5">
            <Row>
              <Col>
                <h3>Race info</h3>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <div>Live: {event.live ? 'Yes' : 'No'}</div>
              </Col>
              <Col xs="12">
                <div>Livemode: {mode}</div>
              </Col>
              <Col xs="12">
                <div>Racemode: {liveHeat.runInfo?.type === 'R' ? 'Race' : 'Qualify'}</div>
              </Col>
              <Col xs="12">
                <div>Track: {event.track.trackName}</div>
              </Col>
              <Col xs="12">
                <div>Event Name: {event.eventName}</div>
              </Col>
              <Col xs="12">
                <div>Event type: {event.eventType.name}</div>
              </Col>
              <Col xs="12">
                <div>Date from: {event.dateFrom}</div>
              </Col>
              <Col xs="12">
                <div>Date to: {event.dateTo}</div>
              </Col>
            </Row>
          </Col>
        </Row>

        <div className="live-logo">
          <img src={logo} alt="" />
        </div>
      </div>
    );
  }
}

export default Event;
