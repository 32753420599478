import fetch from "isomorphic-fetch";

export const checkStatus = response => {
    if (response.status >= 200 && response.status < 300) {
        return response;
    }

    const err = new Error(response.statusText);
    err.statusCode = response.status;
    err.error = response.statusText;
    return Promise.reject(err);
};

export const parseJSON = response => response.json();

/* eslint-disable no-underscore-dangle */
class Api {
    constructor() {
        this.baseUrl =
        `${process.env.REACT_APP_BASE_API_URL}/v1`;
    }

    // eslint-disable-next-line class-methods-use-this
    create(options) {
        return new Api(options);
    }

    fetchLiveHeatToEvent(eventId) {
        return fetch(`${this.baseUrl}/raceadmin/${eventId}`)
            .then(checkStatus)
            .then(parseJSON);
    }
    saveTimeTable(timeTable, eventId) {
        return fetch(`${this.baseUrl}/raceadmin/${eventId}/timetable`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json; charset=utf-8"
            },
            body: JSON.stringify({timeTable})
        })
            .then(checkStatus)
            .then(parseJSON);
    }
}

export default new Api();
