import React, { Component } from "react";
import logo from "./../../assets/images/logo.svg";

import {
    Navbar,
    NavbarBrand,
} from "reactstrap";

class Navigation extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen: false
        };
    }
    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
    render() {
        return (
            <React.Fragment>
                <Navbar color="transparent" light expand="md">
                    <NavbarBrand href="/"><img src={logo} alt="" /></NavbarBrand>
                </Navbar>
            </React.Fragment>
        );
    }
}

export default Navigation;
