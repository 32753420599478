import Api from "./../api/";
import {LIVEMODES} from "./../enums/";
export const FETCH_EVENTS = "event/FETCH_EVENTS";
export const UPDATE_CONNECTED_CLIENTS = "event/UPDATE_CONNECTED_CLIENTS";
export const UPDATE_LIVE_HEAT = "heat/UPDATE_LIVE_HEAT";
export const UPDATE_LIVE_MODE = "heat/UPDATE_LIVE_MODE";
export const FETCH_LIVE_HEAT = "heat/FETCH_LIVE_HEAT";
export const SAVE_TIME_TABLE = "heat/SAVE_TIME_TABLE";

const initialState = {
    event: {},
    liveHeat: {},
    mode: LIVEMODES.TIMING,
    loading: false,
    connectedClients: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
        case `${FETCH_LIVE_HEAT}_FULFILLED`:
            return {
                ...state,
                event: action.payload.event
            };
        case `${UPDATE_LIVE_HEAT}`:
            return {
                ...state,
                liveHeat: action.payload
            };
        case `${UPDATE_LIVE_MODE}`: {
            return {
                ...state,
                mode: action.payload
            };
        }
        case UPDATE_CONNECTED_CLIENTS:
            return {
                ...state,
                connectedClients: action.payload
            };
        default:
            return state;
    }
};

export const updateConnectedClients = connectedClients => ({
    type: UPDATE_CONNECTED_CLIENTS,
    payload: connectedClients
});
export const fetchLiveHeatToEvent = eventId => ({
    type: FETCH_LIVE_HEAT,
    payload: Api.fetchLiveHeatToEvent(eventId)
});
export const updateLiveHeatData = liveHeatData => ({
    type: UPDATE_LIVE_HEAT,
    payload: liveHeatData
});
export const updateLiveMode = mode => ({
    type: UPDATE_LIVE_MODE,
    payload: mode
});
export const saveTimeTable = (timeTable, eventId) => ({
    type: SAVE_TIME_TABLE,
    payload: Api.saveTimeTable(timeTable, eventId)
});
