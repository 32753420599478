import React, { useEffect, useState } from 'react';
import InlineSVG from 'svg-inline-react';
import { GokartW, MotorbikeW, RacingW, RallyCrossW } from "./../icon/Icon";
import "./style.scss";

const EventType = ({ eventType }) => {
    const [svg, setSvg] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [, setIsErrored] = useState(false);
  
    useEffect(() => {
        if(typeof eventType?.icon !== "undefined") {
            fetch(`${process.env.REACT_APP_BASE_ASSETS_URL}/${eventType.icon}`)
                .then(res => res.text())
                .then(setSvg)
                .catch(setIsErrored)
                .then(() => setIsLoaded(true))
        }
        
    }, [eventType]);

    if(eventType?.icon !== undefined) {
        if(isLoaded) {
          return <InlineSVG className="eventType" raw={true} src={svg} />
        } else return null;
    } else {
        // Remove this when fully migrated to cms
        var CurrentEventType = null;
        switch (eventType) {
            case "kart":
                CurrentEventType = GokartW;
                break;
            case "mini-moto":
                CurrentEventType = MotorbikeW;
                break;
            case "rally-cross":
                CurrentEventType = RallyCrossW;
                break;
            case "racing":
                CurrentEventType = RacingW;
                break;
            default:
                CurrentEventType = GokartW;
        }
      
        return <CurrentEventType className="eventType" />;
    }
};

export default EventType;
