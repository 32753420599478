import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "react-router-redux";
import store, { history } from "./store";
import App from "./containers/app";

import "bootstrap/dist/css/bootstrap.min.css";
import "./vendor/font-awesome/css/font-awesome.css";
import "./style/main.scss";

import { unregister } from "./registerServiceWorker";

const target = document.querySelector("#root");

render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <div>
                <App />
            </div>
        </ConnectedRouter>
    </Provider>,
    target
);
unregister();
