import React from "react";
import "./style.scss";
import {
    Col
} from "reactstrap";
import classNames from "classnames";

const EventCard = ({ title, subtitle, ImageComponent, active, onClick, size }) => {
    const cardClass = classNames("event-card", active ? "event-card--active" : "");

    return <Col sm={size} className={cardClass} onClick={onClick}>
              <div className="event-card__body">
                  <ImageComponent />
              </div>
              <div className="event-card__footer">
                  <h5>{title}</h5>
                  {subtitle && <h7>{subtitle}</h7>}
              </div>
          </Col>;
};

export default EventCard;
