import React, { useEffect, useState } from 'react';
import "./style.scss"
import { ReactComponent as DefaultTrack } from "./../../assets/tracks/default.svg";
import { ReactComponent as Axamo } from "./../../assets/tracks/AxamoRing.svg";
import { ReactComponent as Helsingborg } from "./../../assets/tracks/Helsingborg.svg";
import { ReactComponent as Kalmar } from "./../../assets/tracks/Kalmar.svg";
import { ReactComponent as Klippan } from "./../../assets/tracks/Klippan.svg";
import { ReactComponent as Kristianstad } from "./../../assets/tracks/Kristianstad.svg";
import { ReactComponent as Uddevalla } from "./../../assets/tracks/Uddevalla.svg";
import { ReactComponent as Waxjo } from "./../../assets/tracks/Växjö.svg";
import { ReactComponent as Malmo } from "./../../assets/tracks/malmo.svg";
import { ReactComponent as Borlange } from "./../../assets/tracks/Borlänge.svg";
import { ReactComponent as Goteborg } from "./../../assets/tracks/Göteborg.svg";
import { ReactComponent as Pori } from "./../../assets/tracks/Pori.svg";
import { ReactComponent as Kouvola } from "./../../assets/tracks/Kouvola.svg";
import { ReactComponent as Aravete } from "./../../assets/tracks/Aravete.svg";
import { ReactComponent as Tuvangen } from "./../../assets/tracks/Tuvangen.svg";
import { ReactComponent as Kangasala } from "./../../assets/tracks/Kangasala.svg";
import { ReactComponent as Ruuhimaki } from "./../../assets/tracks/Ruuhimäki.svg";
import { ReactComponent as RCVasteras } from "./../../assets/tracks/RC-Västerås.svg";
import { ReactComponent as Honkajoki } from "./../../assets/tracks/Honkajoki.svg";
import { ReactComponent as Vihti } from "./../../assets/tracks/Vihti.svg";
import { ReactComponent as Gallerasen } from "./../../assets/tracks/Gallerasen.svg";
import InlineSVG from 'svg-inline-react';

const Track = ({ trackName, fromCms, track, className }) => {
  const [svg, setSvg] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [, setIsErrored] = useState(false);

  useEffect(() => {
    if(fromCms) {
      fetch(`${process.env.REACT_APP_BASE_ASSETS_URL}/${track.layout}`)
        .then(res => res.text())
        .then(setSvg)
        .catch(setIsErrored)
        .then(() => setIsLoaded(true))
    }
  }, [track, fromCms]);


  if (typeof fromCms !== "undefined" && fromCms) {
    if (isLoaded) {
      return <InlineSVG raw={true} className={className + " track"} src={svg} />
    }
    return <DefaultTrack className={className + " track"} />;
  } else {
    var CurrentTrack = null;
    if (typeof trackName === "undefined") CurrentTrack = DefaultTrack;
    else {
      switch (trackName.toLowerCase()) {
        case "malmö automobilklubb":
          CurrentTrack = Malmo;
          break;
        case "axamoring":
          CurrentTrack = Axamo;
          break;
        case "axamo ring":
          CurrentTrack = Axamo;
          break;
        case "jkc":
          CurrentTrack = Axamo;
          break;
        case "helsingborg":
          CurrentTrack = Helsingborg;
          break;
        case "helsingborgs kk":
          CurrentTrack = Helsingborg;
          break;
        case "kalmar":
          CurrentTrack = Kalmar;
          break;
        case "kalmar mk":
          CurrentTrack = Kalmar;
          break;
        case "ks klippan":
          CurrentTrack = Klippan;
          break;
        case "kristianstads kk":
          CurrentTrack = Kristianstad;
          break;
        case "åsum ring":
          CurrentTrack = Kristianstad;
          break;
        case "kristianstad":
          CurrentTrack = Kristianstad;
          break;
        case "uddevalla":
          CurrentTrack = Uddevalla;
          break;
        case "glimminge motorstadion":
          CurrentTrack = Uddevalla;
          break;
        case "wäxjö":
          CurrentTrack = Waxjo;
          break;
        case "wäxjö ms":
          CurrentTrack = Waxjo;
          break;
        case "hagaslätt":
          CurrentTrack = Waxjo;
          break;
        case "borlänge amsbergs motorstadion":
          CurrentTrack = Borlange;
          break;
        case "team 13":
          CurrentTrack = Goteborg;
          break;
        case "göteborgs krc":
          CurrentTrack = Goteborg;
          break;
        case "pori":
          CurrentTrack = Pori;
          break;
        case "lidköping open":
          CurrentTrack = DefaultTrack;
          break;
        case "lidköping karting":
          CurrentTrack = DefaultTrack;
          break;
        case "kouvola":
          CurrentTrack = Kouvola;
          break;
        case "aravete":
          CurrentTrack = Aravete;
          break;
        case "tuvängen ring":
          CurrentTrack = Tuvangen;
          break;
        case "kangasala":
          CurrentTrack = Kangasala;
          break;
        case "ruuhimäki":
          CurrentTrack = Ruuhimaki;
          break;
        case "honkajoki":
          CurrentTrack = Honkajoki;
          break;
        case "vihti":
          CurrentTrack = Vihti;
          break;
        case "rc-västerås":
          CurrentTrack = RCVasteras;
          break;
        case "malmabanan":
          CurrentTrack = RCVasteras;
          break;
        case "gälleråsen":
          CurrentTrack = Gallerasen;
          break;
        case "gelleråsen arena":
          CurrentTrack = Gallerasen;
          break;
        default:
          CurrentTrack = DefaultTrack;
      }
    }
  
    return <CurrentTrack className={className + " track"} />;
  }
};

export default Track;
