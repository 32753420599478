import React from "react";
import { Route, Switch } from "react-router-dom";
import Event from "../event/Event";
import NotFound from "../notfound/NotFound";
import Layout from "../../components/layout/Layout";

const App = () => (
    <Layout>
        <main>
            <Switch>
            <Route
                exact
                path="/:eventId"
                render={props => (
                    <Event eventId={props.match.params.eventId} />
                )}
            />
                <Route component={NotFound} />
            </Switch>
        </main>
    </Layout>
);

export default App;

//Snabbknappar vad skannar jag
//Hjäl
//Chassi
//motor
//Cylinder

//Däck
//-slicks ange antal däck (4)
//-Regn
//Övrigt
