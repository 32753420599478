export const LIVEMODES = {
  BLANK: 'blank',
  TIMING: 'timing',
  TIMINGENDURANCE: 'timing-endurance',
  TIMINGCONDENSED: 'timing-condensed',
  TIMINGCONDENSEDENDURANCE: 'timing-condensed-endurance',
  STARTGRID: 'startgrid',
  STARTGRID_LEFT: 'startgrid-left',
  STARTGRID_RIGHT: 'startgrid-right',
  TIMETABLE: 'timetable',
  DRIVERSTRIP: 'driverstrip',
  DRIVERTABLE: 'drivertable',
  DRIVERTABLEENDURANCE: 'drivertable-endurance',
  STARTGRIDTABLE: 'startgridtable',
  RESULTTABLE: 'resulttable',
};
export const RACEMODE = {
  race: 'race',
  qualify: 'qualify',
};
