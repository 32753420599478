//import React from 'react'
//import { push } from 'react-router-redux'
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Event from "./../../components/event/Event";
import { fetchLiveHeatToEvent, updateLiveMode, updateLiveHeatData, saveTimeTable} from "../../modules/event";

const mapStateToProps = state => ({
    liveHeat: state.event.liveHeat,
    event: state.event.event,
    mode: state.event.mode,

});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            fetchLiveHeatToEvent,
            updateLiveHeatData,
            updateLiveMode,
            saveTimeTable
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(Event);
